import * as React from "react"
import { Link } from "gatsby"
import MainLayout from '../layouts/MainLayout'
import BodyLayout from '../layouts/BodyLayout'
import MOLKidsLogo from '../assets/images/molkids-logo.svg'

// markup
const NotFoundPage = () => {
  return (
    <MainLayout>
      <section className="error-page">
        <BodyLayout>
          <div className="image-div" style={{position: 'absolute'}}>
            <img src={MOLKidsLogo} alt="Molkids" width="177px" height="155px" style={{ background: '#fff', paddingLeft: 28, paddingRight: 28, paddingTop: 26, paddingBottom: 26 }} />
          </div>
        </BodyLayout>
        <p className="header-error">Sorry, page not found</p>
        <p className="number-error">404</p>
        <div className="button-div">
          <Link className="subscribe-button button-error" to="/">
            Let’s go to MOL KIDS Homepage
          </Link>
        </div>
      </section>
    </MainLayout>
  )
}

export default NotFoundPage
